import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "../../static/css/main.css";

const Job = ({ data }) => {
	const {
		title,
		description,
		tasks,
		skills,
		offers,
	} = data.jobsYaml;

	return (
		<div id="wrapper">
			<Helmet>
				<meta charset="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>entity</title>
				<script src="/js/jquery3.3.1-jquery.main.js" />
				<script src="https://www.google.com/recaptcha/api.js?render=6LeFAqEUAAAAAOBIGFgU67742Rfbw83Yb-nM2_AJ" />
			</Helmet>
			<header id="header">
				<div className="container-fluid">
					<strong className="logo">
						<a href="/">
							<img src="/images/logo.svg" width="94" alt="Entity" />
						</a>
					</strong>
					<a className="nav-opener" href="#"><span /></a>
					<div className="drop">
						<nav className="nav">
							<ul id="menu">
								<li><a href="/#services">services</a></li>
								<li><a href="/#cases">cases</a></li>
								<li><a href="/#ecosystem">ecosystem</a></li>
								<li><a href="/#team">team</a></li>
								<li className="active"><a href="/#jobs">jobs</a></li>
								<li><a className="anchor-link" href="#contact">kontakt</a></li>
							</ul>
						</nav>
					</div>
				</div>
			</header>
			<main id="main">
				<strong className="logo hide">entity</strong>
				<div className="container container-lg">
					<ul className="breadcrumbs viewport move-effect">
						<li><a href="/#jobs">jobs</a></li>
					</ul>
				</div>
				<section className="job-section">
					<div className="container container-lg">
						<h1 className="viewport move-effect">{title}</h1>
						<div className="info-block viewport move-effect" dangerouslySetInnerHTML={{ __html: description }} />
						<div className="requirements-holder viewport fade-effect" id="requirements-holder">
							<div
								className="requirements-item"
								data-anchor-target="#requirements-holder"
								data-bottom-top="transform: translate3d(0, 30vh, 0)"
								data-top-bottom="transform: translate3d(0, -40vh, 0)">
								<div className="heading-block">
									<div className="icon-block">
										<img src="/images/cycle.svg" width="51" alt="description" />
									</div>
									<h2>Dein tägliches Doing</h2>
								</div>
								<ul>
									{tasks.map((task) => {
										return <li key={task}>{task}</li>;
									})}
								</ul>
							</div>
							<div
								className="requirements-item"
								data-anchor-target="#requirements-holder"
								data-bottom-top="transform: translate3d(0, 50vh, 0)"
								data-top-bottom="transform: translate3d(0, -90vh, 0)">
								<div className="heading-block">
									<div className="icon-block">
										<img src="/images/bag.svg" width="51" alt="description" />
									</div>
									<h2>Das hilft Dir weiter</h2>
								</div>
								<ul>
									{skills.map((skill) => {
										return <li key={skill}>{skill}</li>;
									})}
								</ul>
							</div>
							<div
								className="requirements-item"
								data-anchor-target="#requirements-holder"
								data-bottom-top="transform: translate3d(0, 30vh, 0)"
								data-top-bottom="transform: translate3d(0, -55vh, 0)">
								<div className="heading-block">
									<div className="icon-block">
										<img src="/images/culture.svg" width="62" alt="description" />
									</div>
									<h2>Davon kannst Du ausgehen</h2>
								</div>
								<ul>
									{offers.map((offer) => {
										return <li key={offer}>{offer}</li>;
									})}
								</ul>
							</div>
						</div>
					</div>
				</section>
				<div className="cookie-block viewport move-effect">
					<div className="container">
						<div className="text-block">
							<p>Diese Website nutzt ausschließlich technisch notwendige Cookies, um die bestmögliche Funktionalität bieten zu können.</p>
						</div>
						<div className="btn-holder">
							<a href="/privacyagreement">MEHR INFOS</a>
							<a href="#" className="btn btn-sm">OK</a>
						</div>
					</div>
				</div>
			</main>
			<footer id="footer">
				<div className="top-footer">
					<div className="container viewport" id="contact">
						<div className="job-block move-effect">
							<h2 className="h1">Klingt nach Dir? <span>Schicke uns Deine Unterlagen!</span></h2>
							<p>Dein Ansprechpartner ist Stefan Luboschik.</p>
							<div className="btn-holder">
								<a href="mailto:&#119;&#111;&#114;&#107;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;" className="btn btn-decor"><span>&#119;&#111;&#114;&#107;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;</span></a>
							</div>
						</div>
						<div className="contact-block move-effect">
							<h2>entity</h2>
							<address>Schlesische Straße 29-30 Aufgang M, 2. OG <br /> 10997 Berlin</address>
							<ul className="info-list">
								<li><a className="tel" href="tel:+4930233264900">+49 30 23 32 649 00</a></li>
								<li><a href="mailto:&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;">&#105;&#110;&#102;&#111;&#064;&#101;&#110;&#116;&#105;&#116;&#121;&#046;&#100;&#101;</a></li>
							</ul>
							<div className="map-block">
								<img src="/images/map.png" srcSet="/images/map-2x.png 2x" alt="" width="422" height="422" />
							</div>
							<a href="/impressum">Impressum</a>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Job;

export const pageQuery = graphql`
	query($path: String!) {
		jobsYaml (path: { eq: $path }) {
			title
			description
			tasks
			skills
			offers
  		}
	}
`;
